<template>
  <div>
    <svg :height="`${height}px` || '16px'" :width="`${width}px` || '16px'" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.375 7H9C8.60218 7 8.22064 6.84196 7.93934 6.56066C7.65804 6.27936 7.5 5.89782 7.5 5.5V1.125C7.5 1.09185 7.48683 1.06005 7.46339 1.03661C7.43995 1.01317 7.40815 1 7.375 1H4.5C3.96957 1 3.46086 1.21071 3.08579 1.58579C2.71071 1.96086 2.5 2.46957 2.5 3V13C2.5 13.5304 2.71071 14.0391 3.08579 14.4142C3.46086 14.7893 3.96957 15 4.5 15H11.5C12.0304 15 12.5391 14.7893 12.9142 14.4142C13.2893 14.0391 13.5 13.5304 13.5 13V7.125C13.5 7.09185 13.4868 7.06005 13.4634 7.03661C13.4399 7.01317 13.4082 7 13.375 7Z"
        :fill="`${color}`"
      />
      <path
        d="M13.1006 5.89345L8.60656 1.39939C8.59782 1.3907 8.58671 1.38479 8.57462 1.3824C8.56253 1.38001 8.55001 1.38124 8.53862 1.38595C8.52723 1.39066 8.51749 1.39863 8.51062 1.40886C8.50375 1.41909 8.50005 1.43112 8.5 1.44345V5.50001C8.5 5.63262 8.55268 5.7598 8.64645 5.85356C8.74021 5.94733 8.86739 6.00001 9 6.00001H13.0566C13.0689 5.99996 13.0809 5.99627 13.0911 5.98939C13.1014 5.98252 13.1093 5.97278 13.1141 5.96139C13.1188 5.95 13.12 5.93748 13.1176 5.92539C13.1152 5.9133 13.1093 5.90219 13.1006 5.89345Z"
        :fill="`${color}`"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#3b82f6'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
